import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import heroImg from '../images/logoRM.webp'; // Importez votre logo

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      }
    }, 100); // Adjusted interval speed for a smoother progress

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  return (
    <div className="loading-screen text-white">
      <div className="loader"></div>
      {/* Static logo in the center */}
      <div className="logo-container">
        <img src={heroImg} alt="Logo" />
      </div>
    </div>
  );
};

export default LoadingScreen;
